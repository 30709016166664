.App {
  margin-top: 15px;
}

.App .navbar-brand {
  font-weight: bold;
}
.container-fluid {
  flex-wrap: nowrap !important;
}
.navbar-nav {
  flex-direction: row;
}